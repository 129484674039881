<template lang='pug'>
div
  v-container(fluid, justify-center, align-center)
    v-tabs(v-model="activeTab", touchless)
      v-tab(v-for="tab in tabs", :key="tab.key")
        span {{ tab.label }}
      v-tabs-items(v-model="activeTab")
        v-tab-item(v-for="tab in tabs", :key="tab.key")
          DataFilter.ma-1.pa-2(
            :filterProps="filters",
            :items="activeDateset",
            :paginate="false"
          )
            template(v-slot:actions)
              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mr-2.mt-1(
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="getData"
                  )
                    v-icon mdi mdi-refresh
                span Atualizar

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.ml-2.mt-1(
                    v-if="isGerente",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="newCadastroDialog"
                  )
                    v-icon mdi mdi-plus
                span Adicionar

              v-divider(vertical, v-if="isGerente")

              v-tooltip(bottom)
                template(v-slot:activator="{ on }")
                  v-btn.mr-5.mt-1(
                    v-if="isGerente",
                    v-on="on",
                    color="primary",
                    fab,
                    outlined,
                    small,
                    @click="dialogEnviar = true"
                  )
                    v-icon mdi mdi-cube-send
                span Forçar Envio

            template(v-slot:default="{ items }")
              v-data-table.reinfTable(
                :headers="activeHeaders",
                :items="items",
                item-key="id",
                dense,
                :footer-props="footerProps",
                :loading="loading"
              )
                template(v-slot:item.actions="{ item }")
                  td.text-center.pr-2.pl-0
                    v-tooltip(bottom, v-if="isGerente")
                      template(v-slot:activator="{ on }")
                        v-btn(
                          v-on="on",
                          color="primary",
                          fab,
                          small,
                          icon,
                          @click="editCadastroDialog(item)"
                        )
                          v-icon mdi mdi-pencil-circle-outline
                      span Editar

                    v-tooltip(bottom, v-if="isGerente")
                      template(v-slot:activator="{ on }")
                        v-btn(
                          v-on="on",
                          color="primary",
                          fab,
                          small,
                          icon,
                          @click="deleteRegistroDialog(item)"
                        )
                          v-icon mdi mdi-close-circle-outline
                      span excluir

                template(v-slot:item.criadoem="{ item }")
                  td.text-center
                    span(v-if="!loading") {{ new Date(item.criadoem).toLocaleString() }}

                template(v-slot:item.atualizadoem="{ item }")
                  td.text-center
                    span(v-if="!loading") {{ new Date(item.atualizadoem).toLocaleString() }}

  v-dialog(v-model="dialogEnviar", persistent, max-width="500")
    v-card(style="width: 500px")
      v-card-title
        h3 Forçar envio de dados
      v-card-text.mt-4
        div(style="font-size: 14px") Selecione um cliente para encaminhar o comando de atualização das tabelas do REINF. Este comando atualiza todas as tabelas desta funcionalidade.

        v-autocomplete.mt-4(
          v-model="clienteSelected",
          :items="datasetClientes",
          :disabled="datasetClientes.length === 0",
          :loading="datasetClientes.length === 0",
          placeholder="Digite ou selecione um cliente",
          return-object,
          item-text="nome",
          item-value="idcliente",
          label="Cliente",
          dense,
          outlined,
          hide-details
        )

      v-card-actions.pa-2.pt-0.pr-1.pb-4
        v-spacer
        v-btn(color="primary", outlined, @click="dialogEnviar = false")
          span Cancelar
        v-btn(
          color="primary",
          :disabled="dialogEnviarLoadingSend",
          outlined,
          @click="sendCliente()"
        )
          span Enviar

  v-dialog(v-model="dialogCadastrarEditar", persistent, max-width="850")
    v-card(style="width: 850px")
      v-card-title
        h3 {{ tabs[activeTab].label }} - {{ dialogCadastrarEditarType }}
      v-card-text.pt-10(:style="cardTextStyle")
        v-form(ref="form")
          v-row
            v-col.ma-0.pa-2.py-1.text-right(
              :cols="12",
              :md="header.cols",
              v-for="header in filteredHeaders",
              :key="header.value"
            )
              v-text-field(
                v-model="itemManipulate[header.value]",
                :label="header.text",
                outlined,
                :disabled="['codigo'].includes(header.value) && dialogCadastrarEditarType === 'Alteração'",
                dense
              )
      v-card-actions.mr-0
        v-spacer
        v-btn(color="primary", outlined, @click="closeCadastroDialog")
          span Cancelar
        v-btn(color="success", outlined, @click="saveCadastro")
          span Salvar

  v-dialog(v-model="dialogExcluir", max-width="600px")
    v-card
      v-card-title
        h3 Excluir registro
      v-card-text
        h5.ma-0.pa-0 Deseja realmente excluir a registro?
      v-card-actions.mr-0
        v-spacer
        v-btn(outlined, color="primary", @click="deleteRegistroCancel")
          span Cancelar
        v-btn(
          outlined,
          color="error",
          @click="deleteRegistroExecute()",
          :disabled="dialogExcluirInProcess"
        )
          span Excluir
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { clientes as api } from "@/api";

// :disabled="['codigo'].includes(header.value) && [6, 7].includes(activeTab)"

export default {
  name: "reinf",
  data: () => ({
    loading: false,
    isGerente: true,
    tabs: [
      { key: 0, label: "Tipos de Serviços" },
      { key: 1, label: "Natureza de Rendimentos" },
      { key: 2, label: "R-4010" },
      { key: 3, label: "R-4020" },
      { key: 4, label: "R-4040" },
      { key: 5, label: "R-4080" },
      { key: 6, label: "Status HTTP" },
      { key: 7, label: "Status Resposta" },
    ],
    activeTab: 0,
    activeHeaders: [],
    activeDateset: [{}],
    datasetClientes: [],
    dialogEnviar: false,
    dialogEnviarLoadingSend: false,
    clienteSelected: null,
    footerProps: {
      itemsPerPageOptions: [50, 100, 300, 500, -1],
      itemsPerPageAllText: "Todos",
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageText: "Itens por página",
    },
    filters: {
      includeSearch: true,
      items: [],
      searchText: "",
      useSearchHash: false,
    },
    dialogCadastrarEditar: false,
    dialogCadastrarEditarType: "Inclusão",
    itemManipulate: {},
    dialogExcluir: false,
    dialogExcluirInProcess: false,
  }),
  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    async fetchData(url) {
      try {
        this.activeDateset = [{}];
        this.loading = true;

        const response = await csapi.get(url);
        this.activeDateset = this.formatDateGetData(response.data);
      } catch (error) {
        console.error(error);
        this.$toast.error(`Erro ao requisitar dados: ${error.message}`);
      } finally {
        this.loading = false;
      }
    },

    getData() {
      const actions = {
        0: this.getReinfKey0, // Tipos de Serviços
        1: this.getReinfKey1, // Natureza de Rendimentos
        2: this.getReinfKey2, // Reinf R-4010
        3: this.getReinfKey3, // Reinf R-4020
        4: this.getReinfKey4, // Reinf R-4040
        5: this.getReinfKey5, // Reinf R-4080
        6: this.getReinfKey6, // Status Resposta
        7: this.getReinfKey7, // Tipos de Serviços
      };
      const action = actions[this.activeTab] || this.getReinfKey0;
      action.call(this);
    },

    formatDateGetData(data) {
      data.forEach((item) => {
        if (item.dtini) {
          item.dtini = item.dtini.split("-").reverse().join("/");
        }

        if (item.dtfin) {
          item.dtfin = item.dtfin.split("-").reverse().join("/");
        }
      });
      return data;
    },

    createActionColumn() {
      this.activeHeaders.push({
        text: "Ações",
        value: "actions",
        width: "100",
        sortable: false,
        cols: 12,
      });
    },

    getReinfKey0() {
      // Tipos de Serviços
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Descrição", value: "descricao", width: "350", cols: 9 },
        { text: "Criado em", value: "criadoem", align: "center", cols: 3 },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey1() {
      // Natureza de Rendimentos
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Grupo", value: "grupo", width: "350", cols: 9 },
        { text: "Descrição", value: "descricao", width: "350", cols: 12 },
        { text: "FCI", value: "fci", width: "100", cols: 3 },
        {
          text: "Décimo Terceiro",
          value: "decimoterceiro",
          width: "180",
          cols: 3,
        },
        { text: "RRA", value: "rra", width: "100", cols: 3 },
        { text: "Deduções", value: "deducoes", width: "160", cols: 3 },
        {
          text: "Rendimento Isento",
          value: "rendimentoisento",
          width: "200",
          cols: 3,
        },
        { text: "Exterior pf", value: "exteriorpf", width: "160", cols: 3 },
        { text: "Exterior pj", value: "exteriorpj", width: "160", cols: 3 },
        { text: "Declarante", value: "declarante", width: "160", cols: 3 },
        { text: "Tributo", value: "tributo", width: "160", cols: 4 },
        {
          text: "Data início",
          value: "dtini",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Data fim",
          value: "dtfin",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey2() {
      // Reinf R-4010
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Descrição", value: "descricao", width: "350", cols: 9 },
        {
          text: "Residencia fiscal exterior",
          value: "residenciafiscalexterior",
          width: "280",
          cols: 4,
        },
        { text: "Tributo", value: "tributo", width: "160", cols: 4 },
        { text: "Cód. receita", value: "codigoreceita", width: "160", cols: 4 },
        {
          text: "Periodo apuração",
          value: "periodoapuracao",
          width: "200",
          cols: 4,
        },
        {
          text: "Data início",
          value: "dtini",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Data fim",
          value: "dtfin",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey3() {
      // Reinf R-4020
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Descrição", value: "descricao", width: "200", cols: 9 },
        {
          text: "Residencia fiscal exterior",
          value: "residenciafiscalexterior",
          width: "280",
          cols: 4,
        },
        { text: "Tributo", value: "tributo", width: "160", cols: 4 },
        {
          text: "Classificação Tributo 85",
          value: "classificacaotrib85",
          width: "250",
          cols: 4,
        },
        { text: "Cód. receita", value: "codigoreceita", width: "200", cols: 4 },
        {
          text: "Periodo apuração",
          value: "periodoapuracao",
          width: "200",
          cols: 4,
        },
        {
          text: "Data início",
          value: "dtini",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Data fim",
          value: "dtfin",
          width: "200",
          align: "center",
          cols: 4,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey4() {
      // Reinf R-4040
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Descrição", value: "descricao", width: "350", cols: 9 },
        { text: "Cód receita", value: "codigoreceita", width: "140", cols: 3 },
        {
          text: "Período apuração",
          value: "periodoapuracao",
          width: "200",
          cols: 3,
        },
        {
          text: "Data início",
          value: "dtini",
          width: "200",
          align: "center",
          cols: 3,
        },
        {
          text: "Data fim",
          value: "dtfin",
          width: "200",
          align: "center",
          cols: 3,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey5() {
      // Reinf R-4080
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "120", cols: 3 },
        { text: "Descrição", value: "descricao", width: "350", cols: 9 },
        { text: "Cód. Receita", value: "codigoreceita", width: "200", cols: 3 },
        {
          text: "Período apuração",
          value: "periodoapuracao",
          width: "200",
          cols: 3,
        },
        {
          text: "Data início",
          value: "dtini",
          width: "200",
          align: "center",
          cols: 3,
        },
        {
          text: "Data fim",
          value: "dtfin",
          width: "200",
          align: "center",
          cols: 3,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey6() {
      // Status HTTP
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "100", cols: 2 },
        { text: "Tipo", value: "tipo", width: "100", cols: 2 },
        { text: "Descrição", value: "descricao", width: "350", cols: 8 },
        { text: "Ação", value: "acao", width: "100", cols: 4 },
        {
          text: "Cód. status lançamento",
          value: "codigostatuslancamento",
          width: "250",
          cols: 4,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    getReinfKey7() {
      // Status Resposta
      this.activeHeaders = [
        { text: "Código", value: "codigo", width: "100", cols: 2 },
        { text: "Tipo", value: "tipo", width: "200", cols: 2 },
        { text: "Descrição", value: "descricao", width: "350", cols: 8 },
        { text: "Ação", value: "acao", width: "100", cols: 4 },
        {
          text: "Cód. status lançamento",
          value: "codigostatuslancamento",
          width: "220",
          cols: 4,
        },
        {
          text: "Criado em",
          value: "criadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
        {
          text: "Atualizado em",
          value: "atualizadoem",
          width: "200",
          align: "center",
          cols: 12,
        },
      ];

      if (this.isGerente) {
        this.createActionColumn();
      }

      this.fetchData(this.getLink(this.activeTab));
    },

    async getDataClientes() {
      api.refreshAuthProd(this.$store.state.auth);

      try {
        this.datasetClientes = await api.getAll();
        this.datasetClientes.sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        });
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao buscar as informações");
      }
    },

    async sendCliente() {
      this.dialogEnviarLoadingSend = true;

      // Verificar se o cliente foi selecionado
      if (!this.clienteSelected) {
        let msg = "Selecione um cliente para enviar o comando";

        this.$toast.error(msg);
        this.dialogEnviarLoadingSend = false;
        return;
      }

      // Verificar se o cliente está na versão correta para enviar
      let cversio = this.clienteSelected.versao;
      if (this.compareVersions(cversio, "2024.10.1.0") <= 0) {
        let msg = "Server do cliente está em uma versão antiga";
        msg += " e não suporta essa funcionalidade";

        this.$toast.error(msg);
        this.dialogEnviarLoadingSend = false;
        return;
      }

      try {
        const link = `/v1/databridge/integrate`;
        const data = {
          hash: [this.clienteSelected.hash],
          processo: "reinf",
        };

        const response = await csapi.post(link, data);

        if (response.status.toString().startsWith("2")) {
          this.$toast.success("Encaminhado com sucesso");
          this.clienteSelected = null;
          this.dialogEnviar = false;
        }
      } catch (error) {
        let msg = error.response.data.message;
        if (error.response.data.errors) {
          msg = error.response.data.errors[0].message;
        }
        this.$toast.error("Erro ao encaminhar registro. msg server: " + msg);
      }

      this.dialogEnviarLoadingSend = false;
    },

    deleteRegistroDialog(item) {
      this.dialogExcluir = true;
      this.itemManipulate = JSON.parse(JSON.stringify(item));
    },

    deleteRegistroCancel() {
      this.dialogExcluir = false;
      this.itemManipulate = {};
    },

    deleteRegistroExecute() {
      // vai enviar o id para a API para exclusão
      try {
        this.dialogExcluirInProcess = true;
        let link = this.getLink(this.activeTab);
        let id = this.itemManipulate.ordemtabela;

        csapi.delete(`${link}/${id}`).then((res) => {
          if (res.status.toString().startsWith("2")) {
            this.$toast.success("Registro excluído com sucesso");
            this.dialogExcluir = false;
            this.itemManipulate = {};
            this.getData();
          } else {
            this.$toast.error("Erro ao excluir registro");
          }
        });
      } catch (error) {
        this.$toast.error("Erro ao excluir registro");
      } finally {
        this.dialogExcluirInProcess = false;
      }
    },

    adminRole() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const adminRole = [];

      roles.forEach((role) => {
        if (role.name.toUpperCase() === "GERENTE") {
          adminRole.push(role);
        }
      });

      this.isGerente = adminRole.length > 0;
    },

    newCadastroDialog() {
      this.dialogCadastrarEditarType = "Inclusão";
      this.dialogCadastrarEditar = true;

      this.itemManipulate = this.activeHeaders.reduce((acc, header) => {
        if (!["criadoem", "atualizadoem", "actions"].includes(header.value)) {
          acc[header.value] = null;
        }
        return acc;
      }, {});
    },

    editCadastroDialog(item) {
      this.dialogCadastrarEditarType = "Alteração";
      this.dialogCadastrarEditar = true;

      this.itemManipulate = JSON.parse(JSON.stringify(item));
    },

    closeCadastroDialog() {
      this.dialogCadastrarEditar = false;
      this.itemManipulate = {};

      this.dialogCadastrarEditarType = "Inclusão";
    },

    getLink(tab) {
      const links = {
        0: "/v1/databridge/reinf/tipos-servicos",
        1: "/v1/databridge/reinf/natureza-rendimentos",
        2: "/v1/databridge/reinf/r-4010",
        3: "/v1/databridge/reinf/r-4020",
        4: "/v1/databridge/reinf/r-4040",
        5: "/v1/databridge/reinf/r-4080",
        6: "/v1/databridge/reinf/status-http",
        7: "/v1/databridge/reinf/status-resposta",
      };
      return links[tab] || "/v1/databridge/reinf/tipos-servicos";
    },

    async saveCadastro() {
      let link = this.getLink(this.activeTab);

      try {
        let data = JSON.parse(JSON.stringify(this.itemManipulate));

        // Verificar os campos que estão vazios e transformar em null
        for (let key in data) {
          if (typeof data[key] === "string") {
            data[key] = data[key].trim();
          }

          if (data[key] === "") {
            data[key] = null;
          }
        }

        // caso dtini ou dtfin estejam preenchidos, validar se é uma data válida no tipo DateTime e transformar
        // para o formato aceito pela API (YYYY-MM-DD)
        if (data.dtini && data.dtini.trim() !== "") {
          if (data.dtini.length !== 10) {
            this.$toast.error("Data início inválida");
            return;
          }

          let dtinitest = new Date(data.dtini.split("/").reverse().join("-"));
          if (isNaN(dtinitest.getTime())) {
            this.$toast.error("Data início inválida");
            return;
          }
          data.dtini = data.dtini.split("/").reverse().join("-");
        }

        if (data.dtfin && data.dtfin.trim() !== "") {
          if (data.dtfin.length !== 10) {
            this.$toast.error("Data fim inválida");
            return;
          }

          let dtfintest = new Date(data.dtfin.split("/").reverse().join("-"));
          if (isNaN(dtfintest.getTime())) {
            this.$toast.error("Data fim inválida");
            return;
          }
          data.dtfin = data.dtfin.split("/").reverse().join("-");
        }

        // Verificar se é inclusão ou alteração e chamar a API
        let res =
          this.dialogCadastrarEditarType === "Inclusão"
            ? await csapi.post(link, data)
            : await csapi.put(`${link}/${data.ordemtabela}`, data);

        // Verificar o status da resposta e exibir mensagem
        if (res.status.toString().startsWith("2")) {
          const msg =
            this.dialogCadastrarEditarType === "Inclusão"
              ? "incluído"
              : "alterado";
          this.$toast.success("Registro Reinf " + msg + " com sucesso");

          this.dialogCadastrarEditar = false;
          this.dialogCadastrarEditarType = "Inclusão";
          this.itemManipulate = {};

          setTimeout(() => this.getData(), 1000);
        } else {
          this.$toast.error("Erro ao salvar registro");
        }
      } catch (error) {
        // Validações realizadas no backend, não permitindo a gravação
        let message = error.response.data.error.toString().split(",")[0];
        this.$toast.error(`Validação de gravação via API: \n${message}`);
      }
    },

    compareVersions(v1, v2) {
      const v1Parts = v1.split(".").map(Number);
      const v2Parts = v2.split(".").map(Number);

      for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0;
        const v2Part = v2Parts[i] || 0;

        if (v1Part > v2Part) return 1;
        if (v1Part < v2Part) return -1;
      }

      return 0;
    },
  },

  mounted() {
    // Cabeçalho
    this.isControl(true);
    this.setTitle("REINF");

    // Regras de acesso para gerente
    this.adminRole();
    if (this.isGerente) this.createActionColumn();

    // Carrega os dados
    this.getData();
    this.getDataClientes();
  },

  computed: {
    filteredHeaders() {
      const keys = ["criadoem", "atualizadoem", "actions"];
      return this.activeHeaders.filter(
        (header) => !keys.includes(header.value)
      );
    },

    cardTextStyle() {
      return {
        maxHeight: "calc(100vh - 300px)",
        overflowY: "auto",
      };
    },
  },

  watch: {
    activeTab() {
      this.getData();
    },
  },
};
</script>

<style>
.reinfTable .v-data-table__wrapper {
  height: calc(100vh - 290px);
  overflow-y: auto;
}
</style>