<template lang="pug">
v-app
  v-navigation-drawer(
    v-if="logado",
    flat,
    v-model="drawer",
    app,
    mobile-breakpoint="20000",
    width="400"
  )
    v-expansion-panels(v-model="panel", dense, elevation="0")
      v-expansion-panel.pa-0.ma-0(elevation="0")
        v-expansion-panel-header
          LogoControlSoft(left, :h="30")

        v-expansion-panel-content(style="padding: 0px")
          v-row(no-gutters)
            v-col(cols="12")
              div(v-for="(row, i) in menuControlSoft", :key="i")
                v-list-group(
                  v-if="row.items",
                  no-action,
                  dense,
                  :value="row.text"
                )
                  template(v-slot:activator)
                    v-list-item-icon
                      v-icon(small) {{ row.icon }}
                    v-list-item-content
                      b {{ row.text }}

                  v-list-item(
                    v-for="(item, i) in row.items",
                    dense,
                    :to="item.to",
                    link
                  )
                    v-list-item-content
                      b {{ item.text }}
                    v-list-item-icon
                      v-icon(small) {{ item.icon }}

                v-list-item(v-else, dense, :to="row.to", link)
                  v-list-item-icon
                    v-icon(small) {{ row.icon }}
                  v-list-item-content
                    b {{ row.text }}

      v-expansion-panel(elevation="0", v-if="enableSysafra")
        v-expansion-panel-header
          LogoSySafra(left, :h="30")

        v-expansion-panel-content
          v-row(no-gutters)
            v-col(cols="12")
              div(v-for="(row, i) in menuSySafra", :key="i")
                v-list-group(
                  v-if="row.items",
                  no-action,
                  dense,
                  :value="row.text"
                )
                  template(v-slot:activator)
                    v-list-item-icon
                      v-icon(small) {{ row.icon }}
                    v-list-item-content
                      b {{ row.text }}

                  v-list-item(
                    v-for="(item, i) in row.items",
                    dense,
                    :to="item.to",
                    link
                  )
                    v-list-item-content
                      b {{ item.text }}
                    v-list-item-icon
                      v-icon(small) {{ item.icon }}

                v-list-item(v-else, dense, :to="row.to", link)
                  v-list-item-icon
                    v-icon(small) {{ row.icon }}
                  v-list-item-content
                    b {{ row.text }}

  v-app-bar(v-if="logado", app, fixed)
    v-app-bar-nav-icon.ml-2.v-size--x-large(
      @click.stop="drawer = !drawer",
      color="primary"
    )
    v-toolbar-title
      v-row(no-gutters, align="center")
        v-divider.ma-3(vertical)
        LogoControlSoft(v-if="isControl")
        LogoSySafra(v-else)
        v-divider.ma-3(vertical)
        h2 {{ title }}

    v-spacer
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          v-on="on",
          v-bind="attrs",
          icon,
          to="/notificacoes",
          color="primary"
        )
          v-badge(
            x-small,
            :color="notificationStatus ? 'warning' : 'info'",
            overlap,
            bordered,
            dot
          )
            v-icon(:color="notificationStatus ? 'warning' : 'info'")
              | mdi-bell-outline
      span Notificações do Gifnoc

    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-btn(v-on="on", v-bind="attrs", icon, to="/logout", color="primary")
          v-icon mdi-logout
      span Sair

  v-main
    core-messages
    Loader
    core-dialog
    v-fade-transition(mode="out-in")
      router-view
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";
import { socket, state } from "@/socket";

export default {
  data: () => ({
    panel: 0,
    enableSysafra: false,
    temNotificacao: false,
    menuControlSoft: [],
    menuSySafra: [
      {
        icon: "fa-solid fa-shield",
        text: "SySBackup",
        items: [
          {
            to: "/sysafra/sysbackup/monitor",
            icon: "mdi-chart-gantt",
            text: "Monitor",
          },
          {
            to: "/sysafra/sysbackup/validacoes",
            icon: "mdi-seal-variant",
            text: "Validações",
          },
          {
            to: "/sysafra/sysbackup/clientes",
            icon: "fa-solid fa-user",
            text: "Clientes",
          },
          {
            to: "/sysafra/sysbackup/config",
            icon: "fa-solid fa-cog",
            text: "Configurações",
          },
        ],
      },
    ],
  }),
  methods: {
    ...mapMutations("app", ["setDrawer", "setAccessSySafra", "toggleDrawer"]),

    permissionsMenuSySafra() {
      if (this.$store.state.auth.user === null) return false;

      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );

      this.$store.state.app.accessSySafra =
        roles.filter((role) => role.projectid == 250).length > 0;
      this.enableSysafra = this.$store.state.app.accessSySafra;
    },

    montaMenuControlSoft() {
      if (this.$store.state.auth.user === null) return false;
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      this.menuControlSoft = [];

      this.menuControlSoft.push({
        to: "/dashboard",
        icon: "fa-duotone fa-chart-bar",
        text: "Versões",
      });

      this.menuControlSoft.push({
        to: "/clientes",
        icon: "fa-solid fa-user",
        text: "Clientes",
      });

      this.menuControlSoft.push({
        to: "/consulta/produtoswebmobile",
        icon: "mdi-chart-sankey-variant",
        text: "Produtos Web/Mobile - Consulta",
      });

      this.menuControlSoft.push({
        to: "/erros",
        icon: "fa-duotone fa-bug",
        text: "Erros",
      });

      this.menuControlSoft.push({
        to: "/auditoria",
        icon: "fa-solid fa-file-circle-check",
        text: "Auditoria",
      });

      this.menuControlSoft.push({
        to: "/atualizacao/agenda",
        icon: "fa-solid fa-arrow-up-9-1",
        text: "Atualização",
      });

      this.menuControlSoft.push({
        to: "/server/status",
        icon: "fa-solid fa-signal",
        text: "Status dos servidores",
      });

      this.menuControlSoft.push({
        to: "/server/logs",
        icon: "fa-solid fa-list-check",
        text: "Logs dos servidores",
      });

      this.menuControlSoft.push({
        to: "/autenticacao",
        icon: "fa-solid fa-key",
        text: "Autenticação - QRCode de Login",
      });

      this.menuControlSoft.push({
        to: "/autenticacao-monitor",
        icon: "fa-solid fa-key",
        text: "Autenticação Mensal - Monitor Self",
      });

      this.menuControlSoft.push({
        to: "/agronotificacao",
        icon: "fa-solid fa-paper-plane",
        text: "Central de Notificação",
      });

      if (roles.filter((role) => role.projectid == 257).length > 0) {
        this.menuControlSoft.push({
          to: "/datahub",
          icon: "fa-solid fa-magnifying-glass-chart",
          text: "DataHub",
        });
      }

      this.menuControlSoft.push({
        icon: "fa-solid fa-database",
        text: "DataBridge",
        items: [
          {
            to: "/databrigde/instituicoes-financeiras",
            icon: "mdi-bank",
            text: "Instituições Financeiras",
          },
          {
            to: "/databrigde/reinf",
            icon: "mdi-table-sync",
            text: "REINF",
          },
        ],
      });

      this.menuControlSoft.push({
        to: "/csapi/admin-instrument",
        icon: "fa-solid fa-gear",
        text: "CS Api",
      });
    },
  },
  computed: {
    logado() {
      this.montaMenuControlSoft();

      // Tratamento para não exibir parte do site quando determaniadas rotas estiverem ativas
      if (["releases", "releasesVersao"].includes(this.$route.name)) {
        return false;
      }

      return !!this.$store.state.auth.user;
    },
    accessSySafra() {
      return this.$store.state.app.accessSySafra;
    },
    isControl() {
      const { isControl } = this.$store.state.app;
      return isControl;
    },
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    title() {
      return this.$store.state.app.title;
    },
    notificationStatus() {
      return state.notificationStatus;
    },
  },

  watch: {
    async $route() {
      if (this.$store.state.auth.csapi) {
        const { expiresAt } = this.$store.state.auth.csapi;
        const now = new Date().getTime();
        if (!expiresAt || now > new Date(expiresAt).getTime()) {
          await this.$store.dispatch("auth/csApiLogin");
        }

        const { token } = this.$store.state.auth.csapi;
        csapi.addHeader("Authorization", `Bearer ${token}`);
      }
    },

    accessSySafra(newValue) {
      this.enableSysafra = newValue;
    },
  },

  mounted() {
    this.permissionsMenuSySafra();
    this.montaMenuControlSoft();

    if (localStorage.getItem("ipConfigCrypt") === null) {
      localStorage.setItem("ipConfigCrypt", new Date().getTime());
      this.$store.dispatch("auth/logout");
      return;
    }

    const lastConnection = new Date(
      parseInt(localStorage.getItem("ipConfigCrypt"))
    ).toLocaleDateString();
    const now = new Date().toLocaleDateString();

    if (lastConnection !== now) {
      localStorage.setItem("ipConfigCrypt", new Date().getTime());
      this.$store.dispatch("auth/logout");
      return;
    }

    if (!socket.connected) {
      socket.connect();
    }
  },

  beforeDestroy() {
    if (socket.connected) {
      socket.disconnect();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
</style>

<style>
.v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
